import React, { useCallback } from 'react';
import {
  useResourceUpdate,
  useResourceList,
  useWorkspaceShow,
  useSearchParams,
  useLocale
} from '@koopajs/react';
import { IUploadedMinute, ICommittee, IDocument } from 'types';
import { FormControllerTemp } from 'components/temp/FormControllerTemp';
import { DateTime } from 'luxon';
import { ErrorMessage } from '@koopajs/mui';
import { Box, Typography, Button } from '@mui/material';
import prettyBytes from 'pretty-bytes';
import { RenderDocumentIcon } from 'components/RenderDocumentIcon';
import { Operations } from '@koopajs/app';
import { useDispatch } from 'react-redux';
import { DateTimePicker } from 'components/temp/DateTimePickerTemp';
import { SelectTemp } from 'components/temp/SelectTemp';
import { TextField } from 'components/temp/TextFieldTemp';
import { DocumentList } from 'components/DocumentList';

export interface IFile extends File {
  id?: string;
  blobUrl?: string;
}

interface IUploadedMinutesDialogEditForm {
  uploadedMinute: IUploadedMinute;
  setIsInEditMode: (isInEditMode: boolean) => void;
  mainDocument: IDocument;
}

export const UploadedMinutesDialogEditForm: React.FC<IUploadedMinutesDialogEditForm> = (props) => {
  const { uploadedMinute, setIsInEditMode, mainDocument } = props;

  const { resources: committees } = useResourceList<ICommittee>({
    path: '/committees'
  });

  const { t } = useLocale();

  const { workspace } = useWorkspaceShow();
  const search = useSearchParams();

  const keyPrefix = 'UploadedMinutes.UploadedMinutesCard.Form';

  const dispatch = useDispatch();
  const resourcesFetchOperation = Operations.Resources.fetch(dispatch);

  const {
    updateResource: updatedUploadedMinute,
    errorMessage: errorMessageUpdate,
    isProcessing: isUpdateUploadedMinuteProcessing
  } = useResourceUpdate({
    path: '/uploaded-minutes',
    id: uploadedMinute?.id as string
  });

  // UPDATE UPLOADED MINUTE
  const handleSubmitUpdate = useCallback(
    async (data: object) => {
      const typedData = data as {
        committeeId: string;
        title: string;
        startDateTime: string;
      };

      const payload = {
        ...typedData,
        startDateTime: typedData.startDateTime
          ? new Date(typedData.startDateTime + `T00:00:00Z`).toISOString()
          : ''
      };

      const isSuccess = await updatedUploadedMinute(payload);

      if (isSuccess) {
        setIsInEditMode(false);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        await resourcesFetchOperation(workspace?.id || '', `/minutes`, search.searchParams);
      }

      return isSuccess;
    },
    [uploadedMinute?.id]
  );

  return (
    <FormControllerTemp
      onSubmit={handleSubmitUpdate}
      defaultValues={{
        title: uploadedMinute?.title,
        startDateTime:
          uploadedMinute?.startDateTime &&
          DateTime.fromISO(uploadedMinute?.startDateTime, { zone: 'utc' }).toFormat('yyyy-MM-dd'),
        committeeId: uploadedMinute?.committeeId
      }}
    >
      <Box data-cy="uploaded-minute-dialog_card-editable">
        <ErrorMessage error={errorMessageUpdate} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
            flexWrap: 'wrap',
            gap: 1
          }}
        >
          <Box sx={{ display: 'flex', opacity: 0.6, alignItems: 'center', gap: 1, overflow: 'hidden' }}>
            <RenderDocumentIcon fileType={mainDocument?.attachedFile?.format} sx={{ mr: 1 }} />
            <Typography
              variant="body2"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: '100%'
              }}
            >
              {mainDocument?.attachedFile.name}
            </Typography>
            <Box component="span" sx={{ display: { xs: 'none', md: 'flex' } }}>
              &#183;
            </Box>
            <Typography variant="body2" sx={{ minWidth: 'fit-content' }}>
              {prettyBytes(mainDocument?.attachedFile.sizeBytes)}
            </Typography>
          </Box>

          <Button
            type="submit"
            variant="contained"
            sx={{ alignSelf: 'flex-end' }}
            disabled={isUpdateUploadedMinuteProcessing}
            data-cy="uploaded-minute-dialog_card-editable_save-button"
          >
            {t('common:labelSave')}
          </Button>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ display: 'flex', gap: { xs: 0, md: 3 }, flexDirection: { xs: 'column', md: 'row' } }}>
            <DateTimePicker
              name="startDateTime"
              i18n={{ keyPrefix: keyPrefix + '.FieldDate' }}
              type="date"
              inputProps={{ min: '2000-01-01', max: '2099-12-31' }}
              validationRules={{
                pattern: /^[2]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/g
              }}
              sx={{
                '& .MuiInputBase-input': {
                  backgroundColor: 'white'
                }
              }}
            />
            <SelectTemp
              name="committeeId"
              i18n={{ keyPrefix: keyPrefix + '.FieldCommitteeType' }}
              options={committees
                ?.sort((committeeA, committeeB) => {
                  return committeeA.name > committeeB.name ? 1 : -1;
                })
                .map((committee) => ({
                  id: committee.id,
                  label: committee.name
                }))}
            />
          </Box>
          <TextField
            name="title"
            validationRules={{ maxLength: 100 }}
            i18n={{ keyPrefix: keyPrefix + '.FieldTitle' }}
          />

          <DocumentList
            path={`/uploaded-minutes/${uploadedMinute?.id}/documents`}
            isEditable={true}
            isAccordion={true}
            accordionOptions={{ documentsVisible: 2 }}
            sxDocument={{ display: 'flex' }}
          />
        </Box>
      </Box>
    </FormControllerTemp>
  );
};
