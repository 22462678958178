import { IUploadedMinute } from 'types';
import { Typography, Box, Button } from '@mui/material';
import { useLocale } from '@koopajs/react';
import { DocumentList } from '../DocumentList';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import React, { useCallback } from 'react';
import { formatDate } from 'utils/DateTime/formatDate';

interface IUploadedMinutesCardProps {
  uploadedMinutes: IUploadedMinute;
}

export const UploadedMinutesCard: React.FC<IUploadedMinutesCardProps> = (props) => {
  const { uploadedMinutes } = props;

  const minutesType = useCommitteeName(uploadedMinutes?.committeeId || '');

  const hasDocuments = Boolean(uploadedMinutes?.documentsIds?.length);

  const { t, locale } = useLocale();
  const keyPrefix = 'Minutes.MinutesCard';

  const history = useHistory();

  const renderMinuteActionButton = useCallback(() => {
    return (
      <Button
        component={RouterLink}
        to={{
          pathname: `/uploaded-minutes/${uploadedMinutes.id}`,
          search: history.location?.search
        }}
        data-cy="uploaded-minutes-card_action-button"
      >
        {t(keyPrefix + '.viewMinutesButtonLabel')}
      </Button>
    );
  }, [uploadedMinutes.id, minutesType, history.location]);

  return (
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        padding: '24px',
        borderRadius: '4px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        my: 2,
        border: '1px solid #dddddd'
      }}
      data-cy="uploaded-minutes-card"
    >
      <Box>
        {/* Title & Button */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'baseline'
          }}
        >
          <Typography
            sx={{
              mb: 2,
              fontSize: '16px',
              overflow: 'hidden'
            }}
            variant="h3"
          >
            <Box sx={{ display: { xs: 'flex', sm: 'block' }, flexDirection: 'column' }}>
              {formatDate({
                isoString: uploadedMinutes.startDateTime,
                format: 'DATE_HUGE',
                locale,
                isUTC: true
              })}
            </Box>
            <Box
              sx={{
                mt: 2,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: '100%'
              }}
              data-cy="uploaded-minutes-card_title"
            >
              {uploadedMinutes?.title}
            </Box>
          </Typography>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>{renderMinuteActionButton()}</Box>
        </Box>

        {/* Committee */}
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'flex-end', flexWrap: 'wrap' }}>
          {minutesType && (
            <Typography variant="body2" sx={{ opacity: '0.6' }}>
              {minutesType}
            </Typography>
          )}
        </Box>

        {/* Documents */}
        {hasDocuments && (
          <DocumentList
            path={`/uploaded-minutes/${uploadedMinutes.id}/documents`}
            isAccordion={true}
            accordionOptions={{ documentsVisible: 0 }}
            showDivider
          />
        )}
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' }, mt: 2 }}>{renderMinuteActionButton()}</Box>
    </Box>
  );
};
